<template>
  <div :class="[$style.counter, $style[type]]">
    <div
      :class="$style.button"
      @click="$emit('input', value === min ? min : value - step)"
    >
      <Icon :class="$style.minus" name="minus" />
    </div>
    <div>{{ value }} {{ prefix }}</div>
    <div :class="$style.button" @click="$emit('input', value + step)">
      <Icon :class="$style.plus" name="plus" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
export default {
  components: { Icon },
  props: {
    value: {
      type: Number,
      default: 1
    },
    prefix: {
      type: String,
      default: 'шт'
    },
    min: {
      type: Number,
      default: 1
    },
    step: {
      type: Number,
      default: 1
    },
    type: String
  }
}
</script>

<style lang="scss" module>
.counter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid $extra-light-gray;
  background: $smoky;
  margin: 0 3rem 0 1.5rem;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    &.button {
      cursor: pointer;
    }
  }
  svg {
    fill: $light-gray;
    width: 1rem;
  }
  &.primary {
    position: relative;
    width: 3rem;
    margin: 0 3rem;
  }
}
</style>
