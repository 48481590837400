<template>
  <el-form :class="$style.wrapper">
    <h2 :class="$style.title">Галерея</h2>
    <template v-if="photos?.length">
      <el-form-item
        label="Ограничение показываемых картинок"
        :class="$style.counter"
      >
        <Counter
          :value="limit"
          @input="$emit('update:limit', $event)"
          prefix=""
          :min="0"
        />
      </el-form-item>
      <el-form-item label="Отступ" :class="$style.counter">
        <Counter
          :value="margin"
          @input="$emit('update:margin', $event)"
          prefix=""
          :min="0"
        />
      </el-form-item>
      <el-form-item
        v-if="isDirectionColumn"
        label="Количество столбцов"
        :class="$style.counter"
      >
        <Counter
          :value="columns"
          @input="$emit('update:columns', $event)"
          prefix=""
          :min="1"
        />
      </el-form-item>
      <el-form-item
        v-if="isDirectionRow"
        label="Высота строки"
        :class="$style.counter"
      >
        <Counter
          :value="targetRowHeight"
          @input="$emit('update:targetRowHeight', $event)"
          prefix=""
          :min="100"
          :step="100"
        />
      </el-form-item>
      <el-form-item label="Позиционирование">
        <el-select
          :value="direction"
          @change="$emit('update:direction', $event)"
          placeholder="Выбрать"
          :class="$style.select"
        >
          <el-option
            v-for="(item, index) in Object.keys($options.DIRECTIONS_RU)"
            :key="index"
            :label="$options.DIRECTIONS_RU[item]"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <adw-gallery
        :photos="photos"
        :is-editable="true"
        :direction="direction"
        :margin="margin"
        :columns="columns"
        :target-row-height="targetRowHeight"
        :class="$style.gallery"
        :limit="limit"
        @update:photos="$emit('update:photos', $event)"
      />
    </template>
    <el-form-item label="Фото галереи" prop="images">
      <Uploader
        isContentCore
        :files="[]"
        :limit="30"
        :isShowImage="false"
        @upload="uploadGalleryImages"
        :class="$style.uploader"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import Counter from '@/components/atoms/Counter'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import { DIRECTIONS, DIRECTIONS_RU } from '@/constants/gallery'

export default {
  DIRECTIONS,
  DIRECTIONS_RU,
  components: { Uploader, Counter },
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: 'row',
      validator: (value) => ['row', 'column'].includes(value),
    },
    margin: {
      type: Number,
      default: 0,
    },
    columns: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 0,
    },
    targetRowHeight: {
      type: Number,
      default: 300,
    },
  },
  computed: {
    isDirectionRow() {
      return this.direction === DIRECTIONS.ROW
    },
    isDirectionColumn() {
      return this.direction === DIRECTIONS.COLUMN
    },
  },
  methods: {
    uploadGalleryImages(images) {
      this.$emit('update:photos', [
        ...this.photos,
        ...images?.map((item) => ({
          id: item.original,
          src: this.$configData.s3_link + item.original,
          original: item.original,
          width: 4,
          height: 3,
        })),
      ])
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  margin: 2rem 0;
  .title {
    margin: 0 2rem 2rem;
  }
  .counter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & > div {
      width: 18rem;
      & > div {
        margin: 0;
      }
    }
  }
  .gallery {
    max-width: 58.5rem;
    margin: 0 auto 1rem auto;
  }
}
</style>
